import * as React from "react";
import * as data from "data";
import { useEffect, useState } from "react";
import { Link, Redirect, Route, Router, Switch } from "react-router-dom";
import { Landing, logout } from "Landing";
import { browserHistory } from "./kgsaiHistory";
import { Home } from "Home";
import { KGSAILogo } from "Logo";
import { SGFs } from "SGFs";
import { Account } from "Account";
import { Record } from "Record";
import { Documentation } from "Documentation";
import { Download } from "Download";

import { ContactInformation, PrivacyPolicy, RefundPolicy, TermsOfService } from "./legal";

export function PageNotFound(): JSX.Element {
    return (
        <div style={{ display: "flex", flex: "1", alignItems: "center", justifyContent: "center" }}>
            Page not found
        </div>
    );
}

export function routes(): JSX.Element {
    const user = data.get("user");
    (window as any)["user"] = user;

    if (!user) {
        return (
            <Router history={browserHistory}>
                <div>
                    <Switch>
                        <Route path="/terms-of-service" component={TermsOfService} exact />
                        <Route path="/privacy-policy" component={PrivacyPolicy} exact />
                        <Route path="/contact-information" component={ContactInformation} exact />
                        <Route path="/refund-policy" component={RefundPolicy} exact />
                        <Route path="/documentation" component={Documentation} exact />
                        <Route path="/downloads" component={Download} exact />
                        <Route path="/download" component={Download} exact />

                        <Route path="/" component={Landing} exact />
                        <Route path="/*" component={Landing} />
                    </Switch>
                </div>
            </Router>
        );
    }

    return (
        <Router history={browserHistory}>
            <Nav />
            <div>
                <Switch>
                    <Route path="/login" render={() => <Redirect to={`/`} />} />
                    <Route path="/sgfs" component={SGFs} exact />
                    <Route path="/account" component={Account} exact />
                    <Route path="/record/:uuid" component={Record} exact />

                    <Route path="/terms-of-service" component={TermsOfService} exact />
                    <Route path="/privacy-policy" component={PrivacyPolicy} exact />
                    <Route path="/contact-information" component={ContactInformation} exact />
                    <Route path="/refund-policy" component={RefundPolicy} exact />
                    <Route path="/documentation" component={Documentation} exact />
                    <Route path="/downloads" component={Download} exact />
                    <Route path="/download" component={Download} exact />

                    <Route path="/" component={Home} exact />
                    <Route path="/*" component={PageNotFound} />
                </Switch>
            </div>
        </Router>
    );
}

export function Nav(): JSX.Element {
    const user = data.get("user");
    const [path, setPath] = useState(window.location.pathname);
    let active = "";

    useEffect(() => {
        browserHistory.listen((location) => {
            setPath(location.pathname);
        });
    }, []);

    if (path.indexOf("sgfs") > 0) {
        active = "sgf";
    } else if (path.indexOf("account") > 0) {
        active = "account";
    }

    return (
        <header>
            <nav>
                <Link to="/">
                    <KGSAILogo />
                </Link>
                <Link to="/downloads">Download</Link>
                <Link to="/documentation">Documentation</Link>
                <ul>
                    {/* <li className={active === 'sgf' ? 'active' : ''}><Link to="/sgfs">SGFs</Link></li> */}
                    <li className={active === "account" ? "active" : ""}>
                        <Link to="/account">{user.username}</Link>
                        <ul>
                            <li>
                                <Link to="/account">Account</Link>
                            </li>
                            <li>
                                <a onClick={logout}>Logout</a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </nav>
        </header>
    );
}
