import { TypedEventEmitter } from "./TypedEventEmitter";

interface Events {
    [name: string]: any;
}

const defaults: { [id: string]: any } = {};
const store: { [id: string]: any } = {};
const event_emitter = new TypedEventEmitter<Events>();

export function setWithoutEmit(key: string, value: any | undefined): any {
    if (value === undefined) {
        remove(key);
        return value;
    }

    store[key] = value;
    try {
        localStorage.setItem(`kgsai.${key}`, JSON.stringify(value));
    } catch (e) {
        console.warn(
            `Failed to save setting kgsai.${key}, LocalStorage is probably disabled. If you are using Safari, the most likely cause of this is being in Private Browsing Mode.`,
        );
    }

    return value;
}

export function set(key: string, value: any | undefined): any {
    setWithoutEmit(key, value);
    event_emitter.emit(key, value);
    return value;
}

export function setDefault(key: string, value: any): any {
    defaults[key] = value;
    if (!(key in store)) {
        event_emitter.emit(key, value);
    }
    return value;
}

export function remove(key: string): any {
    event_emitter.emit(key, defaults[key]);

    try {
        localStorage.removeItem(`kgsai.${key}`);
    } catch (e) {
        console.error(e);
    }
    if (key in store) {
        const val = store[key];
        delete store[key];
        return val;
    }
    return undefined;
}

export function removePrefix(key_prefix: string): any {
    const hits: { [id: string]: any } = {};

    Object.keys(store).map((key) => {
        if (key.indexOf(key_prefix) === 0) {
            hits[key] = key;
        }
    });

    for (const key in hits) {
        localStorage.removeItem(`kgsai.${key}`);
        delete store[key];
        event_emitter.emit(key, defaults[key]);
    }
}

export function removeAll(): void {
    const keys = [];
    for (const key in store) {
        keys.push(key);
    }
    for (const key of keys) {
        try {
            remove(key);
        } catch (e) {
            console.error(e);
        }
    }
}

export function get(key: "editing", default_value?: boolean): boolean;
export function get(key: string, default_value?: any): any | undefined;
export function get(key: string, default_value?: any): any | undefined {
    if (key in store) {
        return store[key];
    }
    if (key in defaults) {
        return defaults[key];
    }
    return default_value;
}

export function watch(
    key: string,
    cb: (value: any) => void,
    call_on_undefined?: boolean,
    dont_call_immediately?: boolean,
): void {
    event_emitter.on(key, cb);

    const val = get(key);
    if (!dont_call_immediately && (val !== undefined || call_on_undefined)) {
        cb(val);
    }
}

export function unwatch(key: string, cb: (value: any) => void): void {
    event_emitter.off(key, cb);
}

export function dump(key_prefix = "", strip_prefix?: boolean) {
    if (!key_prefix) {
        key_prefix = "";
    }
    const ret: { [id: string]: any } = {};
    const data = Object.assign({}, defaults, store);
    const keys = Object.keys(data);

    keys.sort().map((key) => {
        if (key.indexOf(key_prefix) === 0) {
            const k = strip_prefix ? key.substr(key_prefix.length) : key;
            ret[k] = { union: data[key], value: store[key], default: defaults[key] };
        }
    });
    console.table(ret);
}

try {
    for (let i = 0; i < localStorage.length; ++i) {
        let key = localStorage.key(i) ?? "";
        if (key.indexOf("kgsai.") === 0) {
            key = key.substr(6);
            try {
                const item = localStorage.getItem(`kgsai.${key}`) ?? "";
                store[key] = JSON.parse(item);
            } catch (e) {
                localStorage.removeItem(`kgsai.${key}`);
                console.error(
                    `Data storage system failed to load ${key}. Value was: `,
                    typeof localStorage.getItem(`kgsai.${key}`),
                    localStorage.getItem(`kgsai.${key}`),
                );
                console.error(e);
            }
        }
    }
} catch (e) {
    console.error(e);
}
