import * as React from "react";
import { Link } from "react-router-dom";

export function Legal(): JSX.Element {
    return (
        <div className="Legal">
            <Link to="/terms-of-service">Terms of Service</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
            <Link to="/contact-information">Contact Information</Link>
            <Link to="/refund-policy">Refund Policy</Link>
        </div>
    );
}
