import { get } from "requests";
import { TypedEventEmitter } from "./TypedEventEmitter";

declare let Paddle: any;
let paddle_configured: boolean = false;

interface Events {
    update: BillingConfigInterface;
}

export interface PaymentProcessorInterface {
    id: number;
    system: string;
}

export interface BillingPlanConfigInterface {
    slug: string;
    monthly_paddle_plan_id: number;
    annual_paddle_plan_id: number;
    name: {
        [language: string]: string;
    };
    notes: {
        [language: string]: Array<string>;
    };
    price: {
        [currency: string]: {
            month: number; // current sale price
            //year: number; // current sale price
            //xmonth: number; // crossed off original price
            //xyear: number; // current sale price
        };
    };
}

export interface PlanInterface {
    payment_processor: PaymentProcessorInterface;
    ref_id: string;
}

export interface SubscriptionItemInterface {
    payment_processor: PaymentProcessorInterface;
    ref_id: string;
    plan: PlanInterface;
}

export interface SubscriptionInterface {
    payment_processor: PaymentProcessorInterface;
    ref_id: string;
    paddle_cancel_url: string;
    paddle_update_url: string;
    customer: {
        account: {
            id: number;
            username: string;
            email: string;
        };
    };
    subscription_items: Array<SubscriptionItemInterface>;
    canceled: string | null;
}

export interface BillingConfigInterface {
    sandbox: boolean;
    paddle_vendor_id: number;
    account_id: number;
    email?: string;
    country_code: string;
    country_currency_list: {
        [country: string]: string;
    };
    plans: Array<BillingPlanConfigInterface>;
    subscriptions: Array<SubscriptionInterface>;
}

export let billing_config_event_emitter = new TypedEventEmitter<Events>();
export let billing_config: BillingConfigInterface;

export function reload_billing_config() {
    get("/billing/config")
        .then((config?: BillingConfigInterface) => {
            if (!config) {
                throw new Error("Missing config");
            }

            console.log("Billing config (pre): ", config);
            if (!paddle_configured) {
                paddle_configured = true;
                if (config.sandbox) {
                    console.log("Entering paddle.com sandbox mode");
                    Paddle.Environment.set("sandbox");
                }
                Paddle.Setup({
                    vendor: config.paddle_vendor_id,
                    eventCallback: (obj: any) => {
                        //console.log("Paddle event callback",  p1, p2, p3);
                        console.log("Paddle event callback", obj);

                        if (obj.event === "Checkout.Complete" || obj.event === "Checkout.Close") {
                            console.log("Reloading config");
                            reload_billing_config();
                        }
                    },
                });
            }

            //set_billing_config(config);
            billing_config = config;
            billing_config_event_emitter.emit("update", config);
            return config;
        })
        .catch((e) => console.error(e));
}

reload_billing_config();
