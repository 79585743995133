import * as React from "react";

let VERSION = "3.5.143";
let LAST_UPDATED = "January 26, 2022";

export function Download(): JSX.Element {
    return (
        <div className="Download">
            Welcome to the download page for the latest update of the KGS CGoban software! This
            latest release incorporates some advanced AI analysis features that we hope you will
            enjoy. If you have any trouble, find any bugs, or have any feedback, you can email us at{" "}
            <a href="mailto:support@kgsai.com">support@kgsai.com</a>. Thanks for playing on KGS!
            <h2>
                <span>Download </span>
            </h2>
            <div className="scroll-down-for-instructions">
                (Scroll down for specific directions for each operating system.)
            </div>
            <div className="download-icons">
                <a href={`https://downloads.kgsai.com/CGoban-${VERSION}.msi`}>
                    <span>Windows (64 bit)</span>
                    <img src="/assets/icons8-windows-10.svg" />
                    <span>CGoban-{VERSION}.msi</span>
                </a>
                <a href={`https://downloads.kgsai.com/CGoban-${VERSION}.dmg`}>
                    <span>MacOS 10.14+</span>
                    <img src="/assets/icons8-mac-client-96.png" />
                    <span>CGoban-{VERSION}.dmg</span>
                </a>
                <a href={`https://downloads.kgsai.com/CGoban-${VERSION}.jar`}>
                    <span>Universal</span>
                    <img src="/assets/icons8-java.svg" />
                    <span>CGoban-{VERSION}.jar</span>
                </a>
            </div>
            <div className="last-update">
                <i>Latest version released on {LAST_UPDATED}</i>
            </div>
            <h2>About the new AI features</h2>
            <p>
                The KGS AI service is an automated game review system that analyzes the games you
                play on an array of advanced artificial intelligence servers.
            </p>
            <p>
                KGS always has been and always will be free. Everyone on KGS will now receive a
                quick AI overview at the end of their game along with some suggestions on the three
                biggest mistakes made in the game.
            </p>
            <p>
                For those who want a full AI review and the ability to interactively analyze
                variations, you can sign up for a monthly plan so we can pay for our fancy servers.
                You can easily cancel at anytime with a few clicks.
            </p>
            <h2>Windows installer</h2>
            To install CGoban on Windows simple download the `.msi` and run it. The installer
            process will walk you through the installation process.
            <h2>Mac installer</h2>
            <div className="installation-instructions">
                <div>
                    KGS is not in the Apple Store. By default, Macs are set to not allow apps that
                    aren’t there. It is easy to change this and will allow you to run all sorts of
                    apps, including KGS. Open System Preferences, and choose Security & Privacy:
                </div>
                <img src="/assets/mac-1-security.png" />
                <div>
                    Change Allow apps downloaded from to <b>App Store and Identified developers</b>
                </div>
                <img src="/assets/mac-2.png" />
                <div>
                    Download the KGS app from the webpage if you haven’t already, then double click
                    to open up the .dmg file. You will likely get this message, click on <b>Open</b>
                    . The KGS app is totally safe and will not harm your computer. We have been
                    helping people play go for over 20 years, and are owned by the American Go
                    Foundation. You just need to change your settings to be able to use our app.
                </div>
                <img src="/assets/mac-3.png" />
                <div>
                    Once the disk image is open, drag it to your applications folder. The app is now
                    installed. The first time you run it you will get the message below. If it
                    doesn’t appear, click on System Preferences and Security setting again, you will
                    see it now looks like this. Click on <b>Open Anyway</b>.
                </div>
                <img src="/assets/mac-4.png" />
                <div>
                    This entire process will need to be done only the first time you install the
                    software, and you will also have to allow it to run the first time you start it.
                    After that, you won’t need to do it again (until the next software update at
                    least).
                </div>
            </div>
            <h2>Universal JAR</h2>
            The Universal JAR should work on any computer, including very old ones, that has Java 8
            or higher installed. You can install java from{" "}
            <a target="_blank" href="https://java.com/">
                https://java.com/
            </a>
            . To run, simply download the jar and double click to open it, or you can run it from
            the command line using the command: <code>java -jar CGoban-{VERSION}.jar</code>
            <hr />
            <div className="agf">
                <img src="/assets/agf-logo.png" />
            </div>
        </div>
    );
}
