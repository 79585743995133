import * as React from "react";

export function TermsOfService(): JSX.Element {
    return (
        <div className="legal-container">
            <div className="column well">
                In consideration of your use of the Service, you represent that you are of legal age
                to form a binding contract.
                <h2>Services</h2>
                <div className="legal-paragraph">
                    KGS AI Services, LLC (KGS AI) provides a collection of content, resources,
                    tools, and technologies that enable users to enjoy the game of Go and interact
                    with other users (the “Service”). You understand and agree that the Service is
                    provided "AS-IS" and that KGS AI Services, LLC does not assume any liability for
                    personal information stored through our Service. KGS AI Services, LLC reserves
                    the right at any time and from time to time to modify or discontinue,
                    temporarily or permanently, the Service (or any part thereof) with or without
                    notice. You agree that KGS AI Services, LLC shall not be liable to you or to any
                    third party for any modification, suspension or discontinuance of the Service.
                </div>
                <h2>User Accounts</h2>
                <div className="legal-paragraph">
                    You are responsible for maintaining the confidentiality of the password and
                    account and are fully responsible for all activities that occur under your
                    password or account. KGS AI Services, LLC cannot and will not be liable for any
                    loss or damage arising from your failure to protect your account.
                </div>
                <div className="legal-paragraph">
                    You acknowledge, consent and agree that KGS AI Services, LLC may access,
                    preserve and disclose your account information and Content if required to do so
                    by law or in a good faith belief that such access preservation or disclosure is
                    reasonably necessary to: (a) comply with legal process; (b) enforce the ToS; (c)
                    respond to claims that any Content violates the rights of third parties; (d)
                    respond to your requests for customer service; or (e) protect the rights,
                    property or personal safety of KGS AI Services, LLC, its users and the public.
                </div>
                <h2>Account Limitations</h2>
                You acknowledge that KGS AI Services, LLC may establish general practices and limits
                concerning the use of the Service. You agree that KGS AI Services, LLC has no
                responsibility or liability for the deletion or failure to store any messages, game
                history, or other Content or communications maintained or transmitted by the
                Service. You acknowledge that KGS AI Services, LLC reserves the right to remove,
                disable, or reclaim accounts that are inactive for an extended period of time. You
                further acknowledge that KGS AI Services, LLC reserves the right to modify these
                general practices and limits from time to time.
                <h2>Account Termination</h2>
                You agree that KGS AI Services, LLC may, under certain circumstances and without
                prior notice, immediately terminate your KGS AI Services, LLC account and access to
                the Service. Cause for such termination shall include, but not be limited to, (a)
                breaches or violations of the ToS or other incorporated agreements or guidelines,
                (b) requests by law enforcement or other government agencies, (c) a request by you,
                (d) unexpected technical or security issues or problems, (e) extended periods of
                inactivity, (f) engagement by you in fraudulent or illegal activities, and/or (g)
                nonpayment of any fees owed by you in connection with the Services. Termination of
                your KGS AI Services, LLC account includes the removal of access to some or all
                offerings within the Service, removal of private and public "profile information",
                and at our sole discretion, removal of messages, game history, and other Content
                stored by KGS AI Services, LLC. Further, you agree that all terminations for cause
                shall be made in KGS AI Services, LLC's sole discretion and that KGS AI Services,
                LLC shall not be liable to you or any third party for any termination of your
                account or access to the Service.
                <h2>Content</h2>
                <div className="legal-paragraph">
                    You acknowledge and understand that all Content (including but not limited to
                    text, photographs, and videos), whether it be publicly posted or privately
                    transmitted, is the sole responsibility of the person who created, posted, or
                    otherwise transmitted the Content. KGS AI Services, LLC does not monitor or
                    control user created Content, and as such does not guarantee the accuracy,
                    integrity, quality, or legality of such Content. By using the Service, you
                    understand that you may unintentionally be exposed to Content that is offensive,
                    indecent, or objectionable. Under no circumstances will KGS AI Services, LLC be
                    liable in any way for any Content, including, but not limited to, any errors or
                    omissions in any Content, or any loss or damage of any kind incurred as a result
                    of the use of any Content posted, emailed, transmitted, or otherwise made
                    available by the Service.
                </div>
                <div className="legal-paragraph">
                    You acknowledge and understand that KGS AI Services, LLC reserves the right to
                    move or remove any content on KGS AI Services, LLC Services.
                </div>
                <div className="legal-paragraph">
                    KGS AI Services, LLC does not claim ownership of any Content you submit or
                    otherwise make available through the Service. However, by submitting Content to
                    the service you grant KGS AI Services, LLC the following worldwide,
                    royalty-free, and non-exclusive license: that any Content you submit or make
                    available on or through the Service, the perpetual, irrevocable, and fully
                    sublicensable license to use, distribute, reproduce, modify, adapt, publish,
                    translate, perform, and display such Content (in whole or in part) and to
                    incorporate such Content into other works in any format or medium known or later
                    developed.
                </div>
                <h2>Indemnity</h2>
                <div className="legal-paragraph">
                    You agree to indemnify and hold KGS AI Services, LLC its subsidiaries,
                    affiliates, officers, agents, employees, partners and licensors harmless from
                    any claim or demand, including reasonable attorneys' fees, made by any third
                    party due to or arising out of Content you submit, post, transmit or otherwise
                    make available through the Service, your use of the Service, your connection to
                    the Service, your violation of the ToS, or your violation of any rights of
                    another.
                </div>
                <h2>Links</h2>
                <div className="legal-paragraph">
                    The Service may provide, or third parties may provide, links to other World Wide
                    Web sites or resources. Because KGS AI Services, LLC has no control over such
                    sites and resources, you acknowledge and agree that KGS AI Services, LLC is not
                    responsible for the availability of such external sites or resources, and does
                    not endorse and is not responsible or liable for any Content, advertising,
                    products or other materials on or available from such sites or resources. You
                    further acknowledge and agree that KGS AI Services, LLC shall not be responsible
                    or liable, directly or indirectly, for any damage or loss caused or alleged to
                    be caused by or in connection with use of or reliance on any such Content, goods
                    or services available on or through any such site or resource.
                </div>
                <h2>General Information</h2>
                <div className="legal-paragraph">
                    The Terms of Service constitutes the entire agreement between you and KGS AI
                    Services, LLC and governs your use of the Service, superseding any prior
                    agreements between you and KGS AI Services, LLC with respect to the Service. You
                    also may be subject to additional terms and conditions that may apply when you
                    use or purchase certain other KGS AI Services, LLC services, affiliate services,
                    third-party content or third-party software. The ToS and the relationship
                    between you and KGS AI Services, LLC shall be governed by the laws of the State
                    of Wyoming without regard to its conflict of law provisions. You and KGS AI
                    Services, LLC agree to submit to the personal and exclusive jurisdiction of the
                    courts located within the State of Wyoming. The failure of KGS AI Services, LLC
                    to exercise or enforce any right or provision of the ToS shall not constitute a
                    waiver of such right or provision. If any provision of the ToS is found by a
                    court of competent jurisdiction to be invalid, the parties nevertheless agree
                    that the court should endeavor to give effect to the parties' intentions as
                    reflected in the provision, and the other provisions of the ToS remain in full
                    force and effect. You agree that regardless of any statute or law to the
                    contrary, any claim or cause of action arising out of or related to use of the
                    Service or the ToS must be filed within one (1) year after such claim or cause
                    of action arose or be forever barred.
                </div>
                <h2>DISCLAIMER OF WARRANTIES</h2>
                <div className="legal-paragraph">
                    YOU EXPRESSLY UNDERSTAND AND AGREE THAT:
                    <ol>
                        <li>
                            YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE IS PROVIDED ON
                            AN "AS IS" AND "AS AVAILABLE" BASIS. KGS AI SERVICES, LLC AND ITS
                            SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND
                            LICENSORS EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS
                            OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF
                            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
                        </li>
                        <li>
                            KGS AI SERVICES, LLC AND ITS SUBSIDIARIES, AFFILIATES, OFFICERS,
                            EMPLOYEES, AGENTS, PARTNERS AND LICENSORS MAKE NO WARRANTY THAT (i) THE
                            SERVICE WILL MEET YOUR REQUIREMENTS; (ii) THE SERVICE WILL BE
                            UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE; (iii) THE RESULTS THAT MAY
                            BE OBTAINED FROM THE USE OF THE SERVICE WILL BE ACCURATE OR RELIABLE;
                            (iv) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION OR OTHER
                            MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICE WILL MEET YOUR
                            EXPECTATIONS; AND (v) ANY ERRORS IN THE SOFTWARE WILL BE CORRECTED.
                        </li>
                        <li>
                            ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE
                            SERVICE IS ACCESSED AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE
                            SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF
                            DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL.
                        </li>
                        <li>
                            NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM
                            KGS AI SERVICES, LLC OR THROUGH OR FROM THE SERVICE SHALL CREATE ANY
                            WARRANTY NOT EXPRESSLY STATED IN THE TOS.
                        </li>
                        <li>
                            A SMALL PERCENTAGE OF USERS MAY EXPERIENCE EPILEPTIC SEIZURES WHEN
                            EXPOSED TO CERTAIN LIGHT PATTERNS OR BACKGROUNDS ON A COMPUTER SCREEN OR
                            WHILE USING THE SERVICE. CERTAIN CONDITIONS MAY INDUCE PREVIOUSLY
                            UNDETECTED EPILEPTIC SYMPTOMS EVEN IN USERS WHO HAVE NO HISTORY OF PRIOR
                            SEIZURES OR EPILEPSY. IF YOU, OR ANYONE IN YOUR FAMILY, HAVE AN
                            EPILEPTIC CONDITION, CONSULT YOUR PHYSICIAN PRIOR TO USING THE SERVICE.
                            IMMEDIATELY DISCONTINUE USE OF THE SERVICE AND CONSULT YOUR PHYSICIAN IF
                            YOU EXPERIENCE ANY OF THE FOLLOWING SYMPTOMS WHILE USING THE SERVICE:
                            DIZZINESS, ALTERED VISION, EYE OR MUSCLE TWITCHES, LOSS OF AWARENESS,
                            DISORIENTATION, ANY INVOLUNTARY MOVEMENT, OR CONVULSIONS.
                        </li>
                    </ol>
                </div>
                <h2>LIMITATION OF LIABILITY</h2>
                <div className="legal-paragraph">
                    YOU EXPRESSLY UNDERSTAND AND AGREE THAT KGS AI SERVICES, LLC AND ITS
                    SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS
                    SHALL NOT BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
                    CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR
                    LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF KGS AI
                    SERVICES, LLC HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING
                    FROM:
                    <ol>
                        <li>THE USE OR THE INABILITY TO USE THE SERVICE</li>
                        <li>
                            THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING FROM
                            ANY GOODS, DATA, INFORMATION OR SERVICES PURCHASED OR OBTAINED OR
                            MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THE
                            SERVICE
                        </li>
                        <li>UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA</li>
                        <li>STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SERVICE </li>
                        <li> ANY OTHER MATTER RELATING TO THE SERVICE.</li>
                    </ol>
                    YOU HEREBY ACKNOWLEDGE THAT THIS SECTION SHALL APPLY TO ALL CONTENTS ON ALL
                    SERVERS AND ALL SERVICES. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR
                    EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES YOU AGREE THAT IN
                    THOSE JURISDICTIONS, KGS AI SERVICES, LLC'S LIABILITY WILL BE LIMITED TO THE
                    EXTENT PERMITTED BY LAW.
                </div>
                <h2>Partial Invalidity</h2>
                If any provision of this Agreement is held to be invalid by a court of competent
                jurisdiction, then the remaining provisions shall nevertheless remain in full force
                and effect. KGS AI Services, LLC and the User agree to renegotiate any term held
                invalid and to be bound by mutually agreed substitute provision.
                <h2>Changes to the Terms of Service</h2>
                We may revise the Terms of Service from time to time. The most current version of
                the policy will govern our use of your information and will always be at{" "}
                <a href="https://kgsai.com/terms-of-service">https://kgsai.com/terms-of-service</a>.
                If we make a change to the Terms of Service that, in our sole discretion, is
                material, we will notify you via an email to the email address associated with your
                account (if one is provided and verified), or by prominently displaying a Terms of
                Service notice. By continuing to access or use the Services after those changes
                become effective, you agree to be bound by the revised Terms of Service.
                <div style={{ marginTop: "2em" }}>
                    <em>Effective: Nov 30, 2020</em>
                </div>
            </div>
        </div>
    );
}

export function PrivacyPolicy(): JSX.Element {
    return (
        <div className="legal-container">
            <div className="column">
                <div className="legal-paragraph">
                    <h1 style={{ textDecoration: "underline" }}>Short Version</h1>
                    <strong>
                        KGS AI Services, LLC will not sell your private information to anyone.
                    </strong>{" "}
                    Nor will we share your private information with anyone, except under obvious
                    scenarios detailed in the full privacy policy (such as we are required to do so
                    by law, etc...) If you provide us with additional information for your public
                    profile, then we will make that information available on our website (ie on your
                    profile page), search engines (so people can search for your name for example),
                    and other media.
                </div>
                <h1 style={{ textDecoration: "underline", marginTop: "2em" }}>
                    Official Full Version
                </h1>
                <h2>KGS AI Services, LLC Privacy Policy</h2>
                <div className="legal-paragraph">
                    This Privacy Policy describes how and when KGS AI Services, LLC collects, uses
                    and shares your information when you use our Services. KGS AI Services, LLC
                    receives your information through our various websites, APIs, applications,
                    emails, and other electronic means (the "Services" or "KGS AI Services, LLC").
                    For example, you send us information when you use KGS AI Services, LLC from our
                    website, access KGS AI Services, LLC from an application on your smartphone or
                    tablet, send us emails, or use embedded widgets on other websites or
                    applications. When using any of our Services you consent to the collection,
                    transfer, manipulation, storage, disclosure and other uses of your information
                    as described in this Privacy Policy. Irrespective of which country you reside in
                    or supply information from, you authorize KGS AI Services, LLC to use your
                    information in the United States and any other country where KGS AI Services,
                    LLC operates.
                </div>
                <div className="legal-paragraph">
                    If you have any questions or comments about this Privacy Policy, please contact
                    us at <a href="privacy@">privacy@kgsai.com</a>
                </div>
                <h2>Information Collection and Use</h2>
                <h5>Information Collected Upon Registration:</h5>
                When you create or reconfigure a KGS AI Services, LLC account, or log in via a 3rd
                party authentication service, you provide some profile information, such as your
                username, password, and email address.
                <h5>Cookies:</h5> Like many websites we use cookies to maintain session information
                as a means to persist login state and to preserve some settings for your
                convenience. Most Internet browsers automatically accept cookies. All modern
                internet browsers allow you to disable cookies by changing its settings, however
                some of our Services will not function properly if you do this.
                <h5>Log Data:</h5> Our servers may record information ("Log Data") created by your
                use of our Services. Log Data may include information such as your IP address,
                browser type, operating system, the referring web page, pages visited, location,
                device and application ids, service provider, mobile carrier, search terms, and
                cookie information. Log data is usually recorded when you interact with our
                Services, for example when you visit our website, play a game, sign in, interact
                with our email notifications, or visit a third-party site that includes an embedded
                game board or other widget we provide.
                <h3>Information Sharing and Disclosure</h3>
                <div className="legal-paragraph">
                    We do not disclose your profile information except in the limited circumstances
                    described here.
                </div>
                <div className="legal-paragraph">
                    <h5>Your Consent:</h5> We may share or disclose your information at your
                    direction, such as when you authorize a third-party web client or application to
                    access your KGS AI Services, LLC account.
                </div>
                <div className="legal-paragraph">
                    <h5>Law and Harm:</h5> Notwithstanding anything to the contrary in this Policy,
                    we may preserve or disclose your information if we believe that it is reasonably
                    necessary to comply with a law, regulation or legal request; to protect the
                    safety of any person; to address fraud, security or technical issues. However,
                    nothing in this Privacy Policy is intended to limit any legal defenses or
                    objections that you may have to a third party's, including a government's,
                    request to disclose your information.
                </div>
                <div className="legal-paragraph">
                    <h5>Non-Private or Non-Personal Information:</h5> We may share or disclose your
                    non-private, aggregated or otherwise non-personal information, such as your
                    public user profile information, game information (both ongoing and historical),
                    friends, public and game chats, and forum posts.
                </div>
                <h3>Modifying Your Profile Information</h3>
                If you are a registered user of our Services, we provide you with tools and account
                settings to access or modify the personal information associated with your KGS AI
                Services, LLC account.
                <h3>Our Policy Towards Children</h3>
                Our Services are not directed to persons under 13. If you become aware that your
                child has provided us with profile information without your consent, please contact
                us at <a href="mailto:privacy@">privacy@kgsai.com</a>. We do not knowingly collect
                personally identifiable information from children under 13. If we become aware that
                a child under 13 has provided us with personally identifiable information, we take
                steps to remove such information and terminate the child's account.
                <h3>Changes to this Policy</h3>
                We may revise this Privacy Policy from time to time. The most current version of the
                policy will govern our use of your information and will always be at{" "}
                <a href="https://kgsai.com/privacy-policy">https://kgsai.com/privacy-policy</a>. If
                we make a change to this policy that, in our sole discretion, is material, we will
                notify you via an email to the email address associated with your account (if one is
                provided and verified), or by prominently displaying a Privacy Update notice. By
                continuing to access or use the Services after those changes become effective, you
                agree to be bound by the revised Privacy Policy.
                <div style={{ marginTop: "2em" }}>
                    <em>Effective: Nov 30, 2020</em>
                </div>
            </div>
        </div>
    );
}
export function ContactInformation(): JSX.Element {
    return (
        <div style={{ textAlign: "center", padding: "4em" }}>
            <h3>
                You can reach us via email at{" "}
                <a href="mailto:contact@kgsai.com">contact@kgsai.com</a>
            </h3>
        </div>
    );
}
export function RefundPolicy(): JSX.Element {
    return (
        <div className="legal-container">
            <div className="column well">
                <h1>Refund Policy</h1>

                <div className="legal-paragraph">
                    Subscriptions may be canceled at any time. Refunds for the last payment will be
                    given upon request. All requests must be received within 30 days of the last
                    payment.
                </div>
            </div>
        </div>
    );
}
