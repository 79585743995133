import * as React from "react";
import moment from "moment";
//import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { get } from "requests";
import { errorText } from "error";
import { rankString } from "misc";

interface Props {
    match: {
        params: {
            uuid: string;
        };
    };
}

interface VKPlayerMove {
    move_number: number;
    move: {
        x: number;
        y: number;
    };
    win_rate: number;
    score: number;
    score_delta: number;
    win_rate_delta: number;
    ai_match?: {
        index: number;
        score: number;
        score_delta: number;
        best_score: number;
        best_score_delta: number;
        win_rate: number;
        win_rate_delta: number;
        best_win_rate: number;
        best_win_rate_delta: number;
        visits: number;
        total_visits: number;
    };
}

interface VKPlayer {
    username: string;
    id: any;
    rank: number;
    moves: Array<VKPlayerMove>;
}

interface VK {
    complete: boolean;
    initial_player: "black" | "white";
    black: VKPlayer;
    white: VKPlayer;
}

interface Record {
    uuid: string;
    name: string;
    filename: string;
    created: string;
    game_date: string;
    vk: VK;
}

export function Record(props: Props): JSX.Element {
    const uuid = props.match.params.uuid;
    const [record, setRecord]: [Record | undefined, (r: Record | undefined) => void] =
        useState<Record>();

    useEffect(() => {
        get(`record/${uuid}`)
            .then((rec: any) => {
                setRecord(rec);
            })
            .catch((err) => {
                console.error(errorText(err));
            });
    }, [uuid]);

    if (!record) {
        return <section />;
    }

    let strength = 0;
    try {
        for (const arr of [record.vk.white.moves, record.vk.black.moves]) {
            for (const move of arr) {
                if (move.ai_match) {
                    strength = move.ai_match.total_visits;
                    break;
                }
            }
        }
    } catch (err) {
        console.error(err);
    }

    return (
        <div id="Record">
            <div>
                <table className="details">
                    <tbody>
                        <tr>
                            <th>Name</th>
                            <td>{record.name}</td>
                        </tr>
                        <tr>
                            <th>Game date</th>
                            <td>{moment(record.game_date).format("L")}</td>
                        </tr>
                        <tr>
                            <th>Filename</th>
                            <td>{record.filename}</td>
                        </tr>
                        <tr>
                            <th>Upload date</th>
                            <td>{moment(record.created).format("L")}</td>
                        </tr>
                        <tr>
                            <th>Initial player</th>
                            <td>{record.vk?.initial_player}</td>
                        </tr>
                        <tr>
                            <th>Analysis UUID</th>
                            <td>{record.uuid}</td>
                        </tr>
                        <tr>
                            <th>Strength</th>
                            <td>{strength}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <section>
                <VKPlayerTable vkplayer={record.vk.white} color="White" />
                <VKPlayerTable vkplayer={record.vk.black} color="Black" />
            </section>
        </div>
    );
}

function VKPlayerTable({ vkplayer, color }: { vkplayer: VKPlayer; color: string }): JSX.Element {
    return (
        <div className="VKPlayerTableContainer">
            <h3>
                {vkplayer.username} [{rankString(vkplayer.rank)}]{" "}
                <span className="pull-right">{color}</span>
            </h3>

            <table className="VKPlayerTable">
                <thead>
                    <tr>
                        <th rowSpan={2}>Move</th>
                        <th rowSpan={2}>Score</th>
                        <th rowSpan={2}>&Delta;</th>
                        <th rowSpan={2}>Win%</th>
                        <th rowSpan={2}>&Delta;</th>

                        <th colSpan={3}>AI move match</th>
                    </tr>
                    <tr>
                        <th>#</th>
                        <th>Score</th>
                        <th>Win Rate</th>
                    </tr>
                </thead>
                <tbody>
                    {vkplayer.moves.map((row, idx) => (
                        <tr key={row.move_number}>
                            <td>
                                {row.move_number}
                                {/* ({row.move.x},{row.move.y}) */}
                            </td>
                            <td>{row.score}</td>
                            <td>{row.score_delta}</td>
                            <td>{(row.win_rate * 100.0).toFixed(0)}%</td>
                            <td>{(row.win_rate_delta * 100.0).toFixed(1)}</td>

                            {row.ai_match ? <td>{row.ai_match.index + 1}</td> : <td />}
                            {row.ai_match ? <td>{row.ai_match.score}</td> : <td />}
                            {row.ai_match ? (
                                <td>{(row.ai_match.win_rate * 100.0).toFixed(0)}%</td>
                            ) : (
                                <td />
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

/*
            <table>
                <thead>
                    <tr>
                        <th rowSpan={2}>Win rate</th>
                        <th rowSpan={2}>Score</th>
                        <th colSpan={5}>AI Match</th>
                    </tr>
                    <tr>
                        <th>Pick</th>
                        <th>Score (best)</th>
                        <th>Vists</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        record.win_rates.map((row:any, idx:number) => {
                            return (
                                <tr key={idx}>
                                    <td>{record.win_rates[idx].toFixed(2)}</td>
                                    <td>{record.scores[idx].toFixed(2)}</td>
                                    <td>{record.moves[idx].ai_match?.index >= 0 ? record.moves[idx].ai_match?.index + 1 : null}</td>
                                    <td>{record.moves[idx].ai_match?.score.toFixed(1)}{
                                        record.moves[idx].ai_match?.score !== record.moves[idx].ai_match?.best_score
                                        ? ` ${record.moves[idx].ai_match?.best_score.toFixed(1)}` : ''
                                    }</td>
                                    {record.moves[idx].ai_match ?
                                        <td>{record.moves[idx].ai_match?.visits} ({
                                            (((record.moves[idx].ai_match?.visits || 0) * 100) / (record.moves[idx].ai_match?.total_visits || 1)).toFixed(1)
                                        }%)</td>
                                        : <td />
                                    }
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>
*/
