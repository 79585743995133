//import { browserHistory } from './kgsaiHistory';

export function deepCompare(_x: any, _y: any) {
    // http://stackoverflow.com/questions/1068834/object-comparison-in-javascript
    const leftChain: Array<any> = [];
    const rightChain: Array<any> = [];

    const compare2Objects = (x: any, y: any) => {
        // remember that NaN === NaN returns false
        // and isNaN(undefined) returns true
        if (isNaN(x) && isNaN(y) && typeof x === "number" && typeof y === "number") {
            return true;
        }

        // Compare primitives and functions.
        // Check if both arguments link to the same object.
        // Especially useful on the step where we compare prototypes
        if (x === y) {
            return true;
        }

        // Works in case when functions are created in constructor.
        // Comparing dates is a common scenario. Another built-ins?
        // We can even handle functions passed across iframes
        if (
            (typeof x === "function" && typeof y === "function") ||
            (x instanceof Date && y instanceof Date) ||
            (x instanceof RegExp && y instanceof RegExp) ||
            (x instanceof String && y instanceof String) ||
            (x instanceof Number && y instanceof Number)
        ) {
            return x.toString() === y.toString();
        }

        // At last checking prototypes as good as we can
        if (!(x instanceof Object && y instanceof Object)) {
            return false;
        }

        if (
            Object.prototype.isPrototypeOf.call(x, y) ||
            Object.prototype.isPrototypeOf.call(y, x)
        ) {
            return false;
        }

        if (x.constructor !== y.constructor) {
            return false;
        }

        if (x.prototype !== y.prototype) {
            return false;
        }

        // Check for infinitive linking loops
        if (leftChain.indexOf(x) > -1 || rightChain.indexOf(y) > -1) {
            return false;
        }

        // Quick checking of one object being a subset of another.
        // todo: cache the structure of arguments[0] for performance
        for (const p in y) {
            if (
                Object.prototype.hasOwnProperty.call(y, p) !==
                Object.prototype.hasOwnProperty.call(x, p)
            ) {
                return false;
            } else if (typeof y[p] !== typeof x[p]) {
                return false;
            }
        }

        for (const p in x) {
            if (
                Object.prototype.hasOwnProperty.call(y, p) !==
                Object.prototype.hasOwnProperty.call(x, p)
            ) {
                return false;
            } else if (typeof y[p] !== typeof x[p]) {
                return false;
            }

            switch (typeof x[p]) {
                case "object":
                case "function":
                    leftChain.push(x);
                    rightChain.push(y);

                    if (!compare2Objects(x[p], y[p])) {
                        return false;
                    }

                    leftChain.pop();
                    rightChain.pop();
                    break;

                default:
                    if (x[p] !== y[p]) {
                        return false;
                    }
                    break;
            }
        }

        return true;
    };

    return compare2Objects(_x, _y);
}

/* This code is hacked together from
        https://github.com/simov/slugify/blob/master/slugify.js and
        https://github.com/dodo/node-slug/blob/master/slug.js
 */
export function slugify(str: string) {
    const charMap: { [letter: string]: string } = {
        // latin
        À: "A",
        Á: "A",
        Â: "A",
        Ã: "A",
        Ä: "A",
        Å: "A",
        Æ: "AE",
        Ç: "C",
        È: "E",
        É: "E",
        Ê: "E",
        Ë: "E",
        Ì: "I",
        Í: "I",
        Î: "I",
        Ï: "I",
        Ð: "D",
        Ñ: "N",
        Ò: "O",
        Ó: "O",
        Ô: "O",
        Õ: "O",
        Ö: "O",
        Ő: "O",
        Ø: "O",
        Ù: "U",
        Ú: "U",
        Û: "U",
        Ü: "U",
        Ű: "U",
        Ý: "Y",
        Þ: "TH",
        ß: "ss",
        à: "a",
        á: "a",
        â: "a",
        ã: "a",
        ä: "a",
        å: "a",
        æ: "ae",
        ç: "c",
        è: "e",
        é: "e",
        ê: "e",
        ë: "e",
        ì: "i",
        í: "i",
        î: "i",
        ï: "i",
        ð: "d",
        ñ: "n",
        ò: "o",
        ó: "o",
        ô: "o",
        õ: "o",
        ö: "o",
        ő: "o",
        ø: "o",
        ù: "u",
        ú: "u",
        û: "u",
        ü: "u",
        ű: "u",
        ý: "y",
        þ: "th",
        ÿ: "y",
        ẞ: "SS",
        // greek
        α: "a",
        β: "b",
        γ: "g",
        δ: "d",
        ε: "e",
        ζ: "z",
        η: "h",
        θ: "8",
        ι: "i",
        κ: "k",
        λ: "l",
        μ: "m",
        ν: "n",
        ξ: "3",
        ο: "o",
        π: "p",
        ρ: "r",
        σ: "s",
        τ: "t",
        υ: "y",
        φ: "f",
        χ: "x",
        ψ: "ps",
        ω: "w",
        ά: "a",
        έ: "e",
        ί: "i",
        ό: "o",
        ύ: "y",
        ή: "h",
        ώ: "w",
        ς: "s",
        ϊ: "i",
        ΰ: "y",
        ϋ: "y",
        ΐ: "i",
        Α: "A",
        Β: "B",
        Γ: "G",
        Δ: "D",
        Ε: "E",
        Ζ: "Z",
        Η: "H",
        Θ: "8",
        Ι: "I",
        Κ: "K",
        Λ: "L",
        Μ: "M",
        Ν: "N",
        Ξ: "3",
        Ο: "O",
        Π: "P",
        Ρ: "R",
        Σ: "S",
        Τ: "T",
        Υ: "Y",
        Φ: "F",
        Χ: "X",
        Ψ: "PS",
        Ω: "W",
        Ά: "A",
        Έ: "E",
        Ί: "I",
        Ό: "O",
        Ύ: "Y",
        Ή: "H",
        Ώ: "W",
        Ϊ: "I",
        Ϋ: "Y",
        // turkish
        ş: "s",
        Ş: "S",
        ı: "i",
        İ: "I",
        ğ: "g",
        Ğ: "G",
        // russian
        а: "a",
        б: "b",
        в: "v",
        г: "g",
        д: "d",
        е: "e",
        ё: "yo",
        ж: "zh",
        з: "z",
        и: "i",
        й: "j",
        к: "k",
        л: "l",
        м: "m",
        н: "n",
        о: "o",
        п: "p",
        р: "r",
        с: "s",
        т: "t",
        у: "u",
        ф: "f",
        х: "h",
        ц: "c",
        ч: "ch",
        ш: "sh",
        щ: "sh",
        ъ: "u",
        ы: "y",
        ь: "",
        э: "e",
        ю: "yu",
        я: "ya",
        А: "A",
        Б: "B",
        В: "V",
        Г: "G",
        Д: "D",
        Е: "E",
        Ё: "Yo",
        Ж: "Zh",
        З: "Z",
        И: "I",
        Й: "J",
        К: "K",
        Л: "L",
        М: "M",
        Н: "N",
        О: "O",
        П: "P",
        Р: "R",
        С: "S",
        Т: "T",
        У: "U",
        Ф: "F",
        Х: "H",
        Ц: "C",
        Ч: "Ch",
        Ш: "Sh",
        Щ: "Sh",
        Ъ: "U",
        Ы: "Y",
        Ь: "",
        Э: "E",
        Ю: "Yu",
        Я: "Ya",
        // ukranian
        Є: "Ye",
        І: "I",
        Ї: "Yi",
        Ґ: "G",
        є: "ye",
        і: "i",
        ї: "yi",
        ґ: "g",
        // czech
        č: "c",
        ď: "d",
        ě: "e",
        ň: "n",
        ř: "r",
        š: "s",
        ť: "t",
        ů: "u",
        ž: "z",
        Č: "C",
        Ď: "D",
        Ě: "E",
        Ň: "N",
        Ř: "R",
        Š: "S",
        Ť: "T",
        Ů: "U",
        Ž: "Z",
        // polish
        ą: "a",
        ć: "c",
        ę: "e",
        ł: "l",
        ń: "n",
        ś: "s",
        ź: "z",
        ż: "z",
        Ą: "A",
        Ć: "C",
        Ę: "E",
        Ł: "L",
        Ń: "N",
        Ś: "S",
        Ź: "Z",
        Ż: "Z",
        // latvian
        ā: "a",
        ē: "e",
        ģ: "g",
        ī: "i",
        ķ: "k",
        ļ: "l",
        ņ: "n",
        ū: "u",
        Ā: "A",
        Ē: "E",
        Ģ: "G",
        Ī: "I",
        Ķ: "K",
        Ļ: "L",
        Ņ: "N",
        Ū: "U",
        // lithuanian
        ė: "e",
        į: "i",
        ų: "u",
        Ė: "E",
        Į: "I",
        Ų: "U",
        // romanian
        ț: "t",
        Ț: "T",
        ţ: "t",
        Ţ: "T",
        ș: "s",
        Ș: "S",
        ă: "a",
        Ă: "A",
        // vietnamese
        Ạ: "A",
        Ả: "A",
        Ầ: "A",
        Ấ: "A",
        Ậ: "A",
        Ẩ: "A",
        Ẫ: "A",
        Ằ: "A",
        Ắ: "A",
        Ặ: "A",
        Ẳ: "A",
        Ẵ: "A",
        Ẹ: "E",
        Ẻ: "E",
        Ẽ: "E",
        Ề: "E",
        Ế: "E",
        Ệ: "E",
        Ể: "E",
        Ễ: "E",
        Ị: "I",
        Ỉ: "I",
        Ĩ: "I",
        Ọ: "O",
        Ỏ: "O",
        Ồ: "O",
        Ố: "O",
        Ộ: "O",
        Ổ: "O",
        Ỗ: "O",
        Ơ: "O",
        Ờ: "O",
        Ớ: "O",
        Ợ: "O",
        Ở: "O",
        Ỡ: "O",
        Ụ: "U",
        Ủ: "U",
        Ũ: "U",
        Ư: "U",
        Ừ: "U",
        Ứ: "U",
        Ự: "U",
        Ử: "U",
        Ữ: "U",
        Ỳ: "Y",
        Ỵ: "Y",
        Ỷ: "Y",
        Ỹ: "Y",
        Đ: "D",
        ạ: "a",
        ả: "a",
        ầ: "a",
        ấ: "a",
        ậ: "a",
        ẩ: "a",
        ẫ: "a",
        ằ: "a",
        ắ: "a",
        ặ: "a",
        ẳ: "a",
        ẵ: "a",
        ẹ: "e",
        ẻ: "e",
        ẽ: "e",
        ề: "e",
        ế: "e",
        ệ: "e",
        ể: "e",
        ễ: "e",
        ị: "i",
        ỉ: "i",
        ĩ: "i",
        ọ: "o",
        ỏ: "o",
        ồ: "o",
        ố: "o",
        ộ: "o",
        ổ: "o",
        ỗ: "o",
        ơ: "o",
        ờ: "o",
        ớ: "o",
        ợ: "o",
        ở: "o",
        ỡ: "o",
        ụ: "u",
        ủ: "u",
        ũ: "u",
        ư: "u",
        ừ: "u",
        ứ: "u",
        ự: "u",
        ử: "u",
        ữ: "u",
        ỳ: "y",
        ỵ: "y",
        ỷ: "y",
        ỹ: "y",
        đ: "d",
        // currency
        "€": "euro",
        "₢": "cruzeiro",
        "₣": "french franc",
        "£": "pound",
        "₤": "lira",
        "₥": "mill",
        "₦": "naira",
        "₧": "peseta",
        "₨": "rupee",
        "₩": "won",
        "₪": "new shequel",
        "₫": "dong",
        "₭": "kip",
        "₮": "tugrik",
        "₯": "drachma",
        "₰": "penny",
        "₱": "peso",
        "₲": "guarani",
        "₳": "austral",
        "₴": "hryvnia",
        "₵": "cedi",
        "¢": "cent",
        "¥": "yen",
        元: "yuan",
        円: "yen",
        "﷼": "rial",
        "₠": "ecu",
        "¤": "currency",
        "฿": "baht",
        $: "dollar",
        "₹": "indian rupee",
        // symbols
        "©": "(c)",
        œ: "oe",
        Œ: "OE",
        "∑": "sum",
        "®": "(r)",
        "†": "+",
        "“": '"',
        "”": '"',
        "‘": "'",
        "’": "'",
        "∂": "d",
        ƒ: "f",
        "™": "tm",
        "℠": "sm",
        "…": "...",
        "˚": "o",
        º: "o",
        ª: "a",
        "•": "*",
        "∆": "delta",
        "∞": "infinity",
        "♥": "love",
        "&": "and",
        "|": "or",
        "<": "less",
        ">": "greater",
    };

    if (typeof str !== "string") {
        throw new Error("slugify: string argument expected");
    }

    const slug = str
        .split("")
        .reduce((result, ch) => {
            return result + (charMap[ch] || ch).replace(/[^\w\s$*_+~.()'"!\-:@]/g, "");
        }, "")
        .trim()
        .replace(/[-\s]+/g, "-");

    return slug.toLowerCase();
}

export function getPrintableError(err: any): string {
    if (err === "esc" || err === "cancel" || err === "overlay" || err === "timer") {
        /* We get these from swal (sweetalert) modals when the scape key is pressed, not really errors */
        return "";
    }

    if (!err) {
        return "";
    }

    if (err instanceof Error) {
        console.error(err.stack);
        return err.toString();
    }

    if (typeof err === "string") {
        return err;
    }

    let obj = typeof err === "object" ? err : null;
    if (!obj) {
        console.error(obj);
        try {
            obj = JSON.parse(err.responseText);
        } catch (e) {
            /* ignore */
        }
        if (!obj) {
            console.log(err);
            console.warn(
                "Unable to process error message to a printable error string (1): ",
                err.responseText,
            );
            try {
                console.error(new Error().stack);
            } catch (e) {
                /* ignore */
            }
            return "An unknown error has occurred!";
        }
    }

    if (obj.status === 0 && obj.statusText === "abort") {
        /* ignore aborted requests' */
        return "";
    }

    /*
    if (typeof(obj) === "object") {
        if (obj.game) obj = obj.game;
        if (obj.game_error) obj = obj.game_error;
    */
    let failsafe = 100;
    while (typeof obj === "object" && failsafe--) {
        if (obj instanceof Array) {
            obj = obj[0];
        } else {
            if ("responseText" in obj) {
                try {
                    obj = JSON.parse(obj.responseText);
                } catch (e) {
                    obj = obj.responseText;
                }
            } else if ("errcode" in obj) {
                obj = "errcode";
            } else if ("error" in obj) {
                obj = obj.error;
            } else if ("detail" in obj) {
                obj = obj.detail;
            } else if ("game_error" in obj) {
                obj = obj.game_error;
            } else {
                for (const k in obj) {
                    if (obj[k] === "This field is required.") {
                        obj = "This field is required: " + k;
                        break;
                    } else if (obj[k] === "This field cannot be blank.") {
                        obj = "This field cannot be blank: " + k;
                        break;
                    }
                }
            }
        }
    }

    if (typeof obj === "string") {
        return obj;
    } else {
        //console.warn("Unable to process error message to a printable error string (2): ", JSON.parse(err.responseText));
        console.error(obj);
        console.error(
            "Unable to process error message to a printable error string (2): ",
            err.responseText ? JSON.parse(err.responseText) : err,
        );
        return "An error has occurred";
    }
}

export function errorLogger(...args: Array<any>) {
    const err = getPrintableError(args[0]);
    if (!err) {
        return;
    }
    console.error(err);
}

export function errorAlerter(...args: Array<any>) {
    const err = getPrintableError(args[0]);
    if (!err) {
        return;
    }
    alert(err);
}

export function logout(ev?: any): void {
    localStorage.clear();
    window.location.reload();
}

export function rankString(_r: string | number | undefined, with_tenths?: boolean) {
    let provisional = false;
    let r = 0;

    if (typeof _r === "string") {
        r = parseFloat(_r);
    }
    if (typeof _r === "number") {
        r = _r;
    }
    if (typeof _r === "undefined") {
        return "?";
    }

    if (r > 900) {
        return `${r - 1000 - 36}p`;
    }

    if (r <= -100) {
        provisional = true;
    }
    if (provisional) {
        return "?";
    }

    if (r < 30) {
        if (with_tenths) {
            return `${(Math.ceil((30 - r) * 10) / 10).toFixed(1)}k`;
        } else {
            r = Math.ceil(30 - r);
        }
        return `${r}k`;
    }

    if (with_tenths) {
        return `${(Math.floor((r - 29) * 10) / 10).toFixed(1)}d`;
    } else {
        r = Math.floor(r - 29);
    }
    return `${r}d`;
}
