import * as React from "react";

export function KGSAILogoFull(): JSX.Element {
    return (
        <div id="KGSAILogo" className="full">
            <img src="/assets/logo.png" />
        </div>
    );
    /*
    return (
        <div id="KGSAILogo" className="full">
            KGS
            <div className="sub">
                Artificial Intelligence Services
            </div>
        </div>
    );
    */
}

export function KGSAILogo(): JSX.Element {
    return <div id="KGSAILogo">KGSAI</div>;
}
