import * as React from "react";

export function Documentation(): JSX.Element {
    return (
        <div className="Documentation">
            <h2>Overview</h2>
            The KGS AI system analyzes your games using an array of high powered servers. This
            analysis is automatic for all games<sup>1</sup>
            that you play or upload. This document describes how to review the analysis by yourself
            or with other people, and explains how to interpret the results.
            <div className="sup">
                <sup>1</sup> 9x9, 13x13, and 19x19 games are supported.
            </div>
            <h2>Opening the analysis for a game</h2>
            <h4>Analyzing a played game</h4>
            When you have finished a game you can interactively inspect the analysis performed by
            the AI either by your self or during a review with your opponent.
            <div className="two-column">
                <div className="column">
                    <figure>
                        <figcaption>
                            To review by yourself, click "Options" and then "View Offline"
                        </figcaption>
                        <img src="/assets/documentation/view_offline.png" />
                    </figure>
                </div>
                <div className="column">
                    <figure>
                        <figcaption>
                            To review with your opponent, click "Options" and then "Review Game"
                        </figcaption>
                        <img src="/assets/documentation/review_game.png" />
                    </figure>
                </div>
            </div>
            <h4>Analyzing an uploaded SGF</h4>
            Uploading an SGF, either privately or publically, will automatically run an AI analysis
            on the game and allow you to interactivly review it.
            <div className="center">
                <figure>
                    <img src="/assets/documentation/upload_sgf.png" />
                </figure>
            </div>
            <h2>Interpreting the results</h2>
            When viewing an analysis, your screen will look something like what you see below, with
            a graph showing outcome estimates over time on the graph, and the analysis information
            on the board.
            <div style={{ textAlign: "center" }}>
                <figure>
                    <img src="/assets/documentation/screenshot1.png" />
                </figure>
            </div>
            <div>
                <figure className="key">
                    <img src="/assets/documentation/point_estimation.png" />
                    <figcaption>
                        The graph is showing the estimated score (or win rate) of the game over the
                        course of the game, with the X axis being the move number and the Y axis
                        being the point or win rate estimation (togglable by the blue toggle
                        switch). The green line shows what move you are currently viewing. You can
                        click and drag on the graph with your mouse to quickly move to different
                        moves in the game. When the graph line is above the mid point, it's
                        indicating that the AI estimates that black will be the winner (as also
                        indicated by the darker shading of the graph), when the graph line is below
                        the mid point, it is indicating that white will be the winner.
                    </figcaption>
                </figure>
            </div>
            <div>
                <figure className="key">
                    <img src="/assets/documentation/win_rate.png" />
                    <figcaption>
                        You can click the blue toggle switch to switch between a "Point Estimation"
                        and a "Win Rate" estimation. The point estimation tends to be more useful
                        later in the game when the outcome is likely decided, but you are interested
                        in the affect a move had on the score, while the win rate estimation tends
                        to be more informative earlier in the game when it's harder to estimate the
                        end score. The "Win Rate" is simply the estimated percent chance that the
                        given player will win the game, while the "Point Estimation" is showing the
                        end score estimation.
                    </figcaption>
                </figure>
            </div>
            <div>
                <figure className="key">
                    <img src="/assets/documentation/loading.png" />
                    <figcaption>
                        When the servers are processing a review, the background of the moves that
                        are still being processed are gray. When they are finished, the gray is
                        removed. You can still explore the game while this is going on, however
                        you'll only see results on processed moves.
                    </figcaption>
                </figure>
            </div>
            <div>
                <figure className="key">
                    <img src="/assets/documentation/green_squares.png" />
                    <figcaption>
                        The green squares on the board are showing a few of the more analyzed
                        positions that the AI considered worthwhile to explore. The more intense the
                        green, the more the move was considered. The number you see is the change in
                        either estimated score or estimated win rate for the player, whichever you
                        have toggled to view. These numbers will almost always be negative or zero,
                        this is because the AI is attempting to estimate the result of the game
                        assuming both players will play "perfectly", so a zero or slightly negative
                        move is typically the best move that can be made.
                    </figcaption>
                </figure>
            </div>
            <div>
                <figure className="key">
                    <img src="/assets/documentation/blue_move.png" />
                    <figcaption>
                        The "Blue Move" is the move that the AI would have made.
                    </figcaption>
                </figure>
            </div>
            <div>
                <figure className="key">
                    <img src="/assets/documentation/triangle_move.png" />
                    <figcaption>The "Triangle Move" is the move that was made.</figcaption>
                </figure>
            </div>
            <h4>Exploring variations</h4>
            Laying down stones to explore a variation will automatically trigger an AI analysis of
            that position.
            <div>
                <figure>
                    <img src="/assets/documentation/exploring_variations.png" />
                </figure>
            </div>
            <div>
                <figure className="key">
                    <img src="/assets/documentation/variation_graph.png" />
                    <figcaption>
                        The graph will progress like normal, but you will notice a green line
                        indicating the score estimation or win rate of the variation.
                    </figcaption>
                </figure>
            </div>
            <h4>Free Reviews</h4>
            If you're not signed up yet, you will still get a quick overview of how the game went,
            as well as three moves fully reviewed.
            <div>
                <figure>
                    <img src="/assets/documentation/free_view.png" />
                </figure>
            </div>
            <div>
                <figure className="key">
                    <img src="/assets/documentation/free_review_and_loading.png" />
                    <figcaption>
                        Navigating to one of the three red dots, which indicate the move has been
                        analyzed, will show you the analysis for that position. If you click on a
                        green square, a rough playout will be displayed on the board indicating how
                        the AI thought the game might progress.
                    </figcaption>
                </figure>
            </div>
            <div>
                <figure className="key">
                    <img src="/assets/documentation/ghost_stone.png" />
                    <figcaption>
                        When exploring an analyzed position of a free review, "Ghost Stones" will be
                        shown on the board, which indicate how the AI thought the game might
                        progress if it were playing itself.
                    </figcaption>
                </figure>
            </div>
            <h4>Disabling and enabling the AI results.</h4>
            If at any point you want to disable the AI analysis, simple click "Options" and "Disable
            AI". You can easily re-enable the AI analysis by once again clicking "Options" and then
            clicking "Enable AI".
            <div className="two-column">
                <div className="column">
                    <figure>
                        <img src="/assets/documentation/disable_ai.png" />
                    </figure>
                </div>
                <div className="column">
                    <figure>
                        <img src="/assets/documentation/enable_ai.png" />
                    </figure>
                </div>
            </div>
        </div>
    );
}
