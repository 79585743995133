import * as React from "react";
import { ChangeEvent, FormEvent, KeyboardEvent, useEffect, useState } from "react";
import { put } from "requests";
import { _ } from "translate";
import * as data from "data";
import { ErrorResponse, errorText } from "error";
import { KGSAILogoFull } from "Logo";
import { Pricing } from "Pricing";
import { Legal } from "Legal";

export function Landing(): JSX.Element {
    return (
        <div id="Landing">
            <KGSAILogoFull />

            <p>
                The KGS artificial intelligence service provides automated reviews of your games
                immediately after they finish. Each game is analyzed by multiple high powered
                servers providing you with insights into each move, and allowing you to explore
                variations with one of the most advanced Go AIs in the world.
            </p>

            <div className="download">
                To download the new client, head to the <a href="/downloads">downloads</a> page
            </div>

            <figure>
                <figcaption>Example screenshot</figcaption>
                <img src="/assets/img2.png" />
            </figure>

            <p>
                The interface is simple yet powerful. An interactive graph on the right shows the
                estimated outcome of the game over time, and selecting a move shows an analysis of
                the game at that time. On the board you’ll see the "Blue Move", which is the move
                the AI would have made, along with several green squares showing some alternative
                moves that the AI thought were relevant. The brighter the green, the more the move
                was analyzed. The numbers indicate the effect the move would have on the estimated
                outcome of the game, for instance -0.5 indicates that the current player’s estimated
                score would drop by half a point if that move was made. Because the AI assumes
                nearly perfect moves are being made, these numbers are usually slightly negative, or
                in the best case, zero, which indicates the AI thought it was a very good move.
            </p>

            <p>
                If you want to explore any game variations to see how the AI would react or play,
                simply place stones on the board and the AI will automatically analyze the new
                position, giving you immediate feedback on how a variation might have played out.
            </p>

            <p>
                This functionality is built into the latest versions of the KGS Java Client, so
                there's nothing new to install or setup, no need for expensive hardware, simply sign
                up with your KGS username and start playing.
            </p>

            <Pricing />

            <Login />

            <div className="about">
                <div>
                    <img src="/assets/agf-logo.png" />
                </div>
                KGSAI Services, LLC is a joint partnership between the American Go Foundation and
                Online-Go.com, Inc.
            </div>

            <Legal />
        </div>
    );
}

export function Login(): JSX.Element {
    const [username, _setUsername] = useState("");
    const [password, _setPassword] = useState("");
    const [err, _setErr] = useState(null as ErrorResponse | null);
    const [loggingIn, setLoggingIn] = useState(false);

    function setUsername(ev: ChangeEvent<HTMLInputElement>) {
        const username = ev.target.value;
        _setErr(null);
        _setUsername(username);
    }

    function setPassword(ev: ChangeEvent<HTMLInputElement>) {
        const password = ev.target.value;
        _setErr(null);
        _setPassword(password);
    }

    useEffect(() => {
        document.getElementById("username")?.focus();
    }, []);

    function advanceOrSubmit() {
        if (username.trim() === "") {
            document.getElementById("username")?.focus();
            return;
        }
        if (password.trim() === "") {
            document.getElementById("password")?.focus();
            return;
        }

        if (loggingIn) {
            return;
        }

        setLoggingIn(true);

        put("auth/login", { username, password })
            .then((res) => {
                const user = res;
                setLoggingIn(false);
                console.log(user);
                data.set("user", res);
                window.location.reload();
            })
            .catch((err) => {
                setLoggingIn(false);
                console.log(err);
                _setErr(err);
            });
    }

    function keyPress(ev: KeyboardEvent<HTMLInputElement>) {
        if (ev.type === "keypress") {
            if (ev.charCode === 13) {
                ev.preventDefault();
                advanceOrSubmit();
            }
        }
    }

    function onSubmit(ev: FormEvent<HTMLFormElement>) {
        advanceOrSubmit();
        ev.preventDefault();
    }

    let beta: boolean =
        window.location.hostname.indexOf("beta") >= 0 ||
        window.location.hostname.indexOf("dev") >= 0;

    return (
        <div id="Login">
            <section id="LoginBoxContainer">
                <div>
                    Login with your KGS username and password to sign up for the service, and thanks
                    for playing on KGS!
                </div>

                {beta && (
                    <div className="beta">
                        This is the test site for KGS AI. Please log in using your KGS Beta login
                        credentials.
                    </div>
                )}

                <form id="LoginBox" onSubmit={onSubmit}>
                    <input
                        id="username"
                        type="text"
                        value={username}
                        onChange={setUsername}
                        onKeyPress={keyPress}
                        placeholder={_("KGS Username")}
                        disabled={loggingIn}
                    />
                    <input
                        id="password"
                        type="password"
                        value={password}
                        onChange={setPassword}
                        placeholder={_("KGS Password")}
                        onKeyPress={keyPress}
                        disabled={loggingIn}
                    />
                    {err ? <span className="error">{errorText(err)}</span> : null}
                    <button onClick={advanceOrSubmit} disabled={loggingIn}>
                        Login
                    </button>
                </form>
            </section>
        </div>
    );
}

export function logout() {
    data.remove("user");
    window.location.reload();
}
