import * as React from "react";
import * as data from "data";
import { _ } from "translate";
import {
    billing_config_event_emitter,
    //reload_billing_config,
    BillingConfigInterface,
    //BillingPlanConfigInterface,
    SubscriptionInterface,
    billing_config as global_billing_config,
} from "pricing";
import { Pricing } from "Pricing";
import { Legal } from "Legal";

declare let Paddle: any;

export function Account(): JSX.Element {
    const user = data.get("user");
    const [billing_config, set_billing_config]: [
        BillingConfigInterface | undefined,
        (d: BillingConfigInterface | undefined) => void,
    ] = React.useState<BillingConfigInterface | undefined>(global_billing_config);

    React.useEffect(() => {
        billing_config_event_emitter.on("update", set_billing_config);
        return () => {
            billing_config_event_emitter.off("update", set_billing_config);
        };
    }, []);

    if (!billing_config) {
        return <></>;
    }

    let existing_subscriptions = billing_config.subscriptions.filter(
        (subscription) =>
            subscription.payment_processor.system === "paddle" && !subscription.canceled,
    );
    //let existing_subscription = existing_subscriptions.length > 0 ? existing_subscriptions[0] : null;

    function paddle_cancel_gen(subscription: SubscriptionInterface): () => void {
        return () => {
            window.location.href = subscription.paddle_cancel_url;
        };
    }

    function paddle_update_gen(subscription: SubscriptionInterface): () => void {
        return () => {
            window.location.href = subscription.paddle_update_url;
        };
    }

    let beta: boolean =
        window.location.hostname.indexOf("beta") >= 0 ||
        window.location.hostname.indexOf("dev") >= 0;

    return (
        <main id="Account">
            <div className="blurb">
                Your support makes a huge difference, so thanks for signing up! Note, you can cancel
                at any time, and if you ever want a refund simply send an email to{" "}
                <a href="mailto:contact@kgsai.com">contact@kgsai.com</a> and we'll get it taken care
                of, no questions asked.
            </div>

            {beta && (
                <div className="beta">
                    This is the test site for KGS AI. Signing up here affects your games on the KGS
                    Beta server, not the live server. To sign up here, use the special test credit
                    card: <code>4111 1111 1111 1111</code> with any expiration date in the future,
                    any zip code, and any CVV number. Review strengths are greatly reduced and are
                    run on much slower hardware for testing.
                </div>
            )}

            {user?.auth_level?.indexOf("admin") >= 0 && (
                <div className="admin">
                    Thanks for your service as an admin! You do not need to sign up for this
                    service, you will automatically receive the top level plan as a token of our
                    thanks for helping keep the KGS community great.
                </div>
            )}

            <Pricing />

            <div className="modify-container">
                {existing_subscriptions.map((subscription) => (
                    <div key={subscription.ref_id} className="modify-subscription">
                        <button onClick={paddle_update_gen(subscription)}>
                            {_("Change Payment Method")}
                        </button>
                        <button onClick={paddle_cancel_gen(subscription)}>
                            {_("Cancel Subscription")}
                        </button>
                    </div>
                ))}
            </div>

            <div className="blurb">
                Note: We use Paddle.com as our "Merchant of Record", which means they deal with
                safely collecting payment details and storing them securely. In practice what this
                means to you is the charges generated will be from Paddle.com with a note that they
                are on behalf of KGSAI Services.
            </div>

            <Legal />
        </main>
    );
}
